import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BaseComponentProps, Tooltip, Typography } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { PanelContent, DownloadAudioButton } from '@srnade/web/components';
import { BonusContent, BonusContent as BonusContentType } from '@srnade/web/__generated__/graphql';
import { ProductBonusContent } from '../ProductBonusContent';
import { ProductBonusContentLink } from '../ProductBonusContent/ProductBonusContentLink.component';
import { GenericBonusContentSlide, Slide, StreamableBonusContentSlide, clsx } from 'yet-another-react-lightbox';
import { BonusContentLightBox } from '../BonusContentLightBox';
import { ViewNow } from '../ViewNow/ViewNow.component';

interface BonusContentProps {
    locked: boolean;
    bonusContents: BonusContentType[];
    onGetDownloadUrl: (assetId: string) => Promise<string | undefined>;
    onGetStreamableUrl: (assetId: string) => Promise<string | undefined>;
    onGetVideoAssetThumbnailUrl: (assetId: string) => Promise<string | undefined>;
    editionArtworkUrl: string;
    showStreamDownload?: boolean;
    showTitle?: boolean;
    pressingTitle: string;
    artistName: string;
    onOpenLightBox: () => void;
}

function handlePromise<T>(promise: PromiseSettledResult<T>): T | null {
    if (promise.status === 'fulfilled') {
        return promise.value;
    } else {
        return null;
    }
}

export const StreamableBonusContent: React.FC<BonusContentProps & BaseComponentProps> = ({
    className,
    bonusContents = [],
    locked = true, // override to lock all content
    onGetDownloadUrl,
    onGetStreamableUrl,
    onGetVideoAssetThumbnailUrl,
    showStreamDownload = false,
    showTitle = true,
    editionArtworkUrl,
    pressingTitle,
    artistName,
    onOpenLightBox,
}) => {
    const { t } = useTranslation('components', { keyPrefix: 'bonusContent' });
    const [loadingStates, setLoadingStates] = useState<{ [assetId: string]: boolean }>({});
    const [bonusContentSlides, setBonusContentSlides] = useState<Slide[]>([]);
    const [openLightBox, setOpenLightBox] = useState(false);
    const [startSlideIndex, setSlideIndex] = useState(0);
    // Add state to store download URLs
    const [downloadUrls, setDownloadUrls] = useState<{ [key: string]: string }>({});

    const isConsumable = useCallback((bonusContent: BonusContentType): boolean => {
        return Boolean(
            (bonusContent.streamable === true || bonusContent.downloadable === true) &&
                bonusContent.active === true &&
                bonusContent?.displayName &&
                bonusContent?.displayName?.length > 0,
        );
    }, []);

    const isLinkConsumable = useCallback((bonusContent: BonusContentType): boolean => {
        return Boolean(
            bonusContent.active === true && bonusContent?.displayName && bonusContent?.displayName?.length > 0,
        );
    }, []);

    const filteredBonusContent = useMemo(
        () => bonusContents.filter((bonusContent) => isConsumable(bonusContent)),
        [bonusContents, isConsumable],
    );

    const setLoadingState = (assetId: string, isLoading: boolean) => {
        setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [assetId]: isLoading,
        }));
    };

    // This will trigger <a /> that lives in <ProductBonusContent />
    const triggerDownload = (downloadUrl: string) => {
        const anchorTag = document.getElementById('downloadBonusContent') as HTMLAnchorElement;
        if (anchorTag) {
            anchorTag.href = downloadUrl;
            anchorTag.click();
        }
    };

    const handleOnDownload = (assetId: string) => {
        setLoadingState(assetId, true);
        const downloadUrl = downloadUrls[assetId];

        if (downloadUrl) {
            triggerDownload(downloadUrl);
        }

        setLoadingState(assetId, false);
    };

    const handleOnDownloadInSlide = useCallback(
        async (bonusContent: BonusContent) => {
            if (bonusContent.assetId && bonusContent.downloadable && onGetDownloadUrl) {
                return await onGetDownloadUrl(bonusContent?.assetId);
            }
            return;
        },
        [onGetDownloadUrl],
    );

    const handleOnRefreshStreamUrl = useCallback(
        (bonusContent: BonusContent) => {
            if (bonusContent.assetId && bonusContent.streamable && onGetStreamableUrl) {
                return onGetStreamableUrl(bonusContent.assetId);
            }
            return;
        },
        [onGetStreamableUrl],
    );

    const fetchBonusContentSlideData = useCallback(async () => {
        const imageBonusContent: BonusContentType[] = [];
        const videoBonusContent: BonusContentType[] = [];
        const streamableBonusContent: BonusContentType[] = [];

        const getThumbnailPromise: Array<Promise<string | undefined>> = [];
        const getStreamablePromise: Array<Promise<string | undefined>> = [];
        const getVideoAssetThumbnailPromise: Array<Promise<string | undefined>> = [];

        const imageAssetMap = new Map();
        const streamableAssetMap = new Map();
        const videoThumbnailAssetMap = new Map();

        filteredBonusContent.forEach((bonusContent) => {
            if (!bonusContent?.assetId || !bonusContent?.active) return;

            if (bonusContent.mimeType?.includes('image')) {
                imageBonusContent.push(bonusContent);
                getThumbnailPromise.push(onGetDownloadUrl(bonusContent.assetId));
            } else if (bonusContent.mimeType?.includes('video')) {
                videoBonusContent.push(bonusContent);
                streamableBonusContent.push(bonusContent);
                getVideoAssetThumbnailPromise.push(onGetVideoAssetThumbnailUrl(bonusContent.assetId));
                getStreamablePromise.push(onGetStreamableUrl(bonusContent.assetId));
            } else if (bonusContent.mimeType?.includes('audio')) {
                streamableBonusContent.push(bonusContent);
                getStreamablePromise.push(onGetStreamableUrl(bonusContent.assetId));
            }
        });

        const promises = await Promise.allSettled([
            Promise.allSettled(getThumbnailPromise),
            Promise.allSettled(getStreamablePromise),
            Promise.allSettled(getVideoAssetThumbnailPromise),
        ]);

        const imagesResolvedPromises = handlePromise(promises[0]);
        if (imagesResolvedPromises?.length) {
            imagesResolvedPromises.forEach((imageResolvedPromise, index) => {
                const imageSrc = handlePromise(imageResolvedPromise);
                imageAssetMap.set(imageBonusContent[index].assetId, imageSrc);
            });
        }

        const streamableResolvedPromises = handlePromise(promises[1]);
        if (streamableResolvedPromises?.length) {
            streamableResolvedPromises.forEach((streamableResolvedPromise, index) => {
                const streamableUrl = handlePromise(streamableResolvedPromise);
                streamableAssetMap.set(streamableBonusContent[index].assetId, streamableUrl);
            });
        }

        const videoAssetThumbnailResolvedPromises = handlePromise(promises[2]);
        if (videoAssetThumbnailResolvedPromises?.length) {
            videoAssetThumbnailResolvedPromises.forEach((videoAssetThumbnailResolvedPromise, index) => {
                const videoThumbnailUrl = handlePromise(videoAssetThumbnailResolvedPromise);
                videoThumbnailAssetMap.set(videoBonusContent[index].assetId, videoThumbnailUrl);
            });
        }

        return {
            imageAssetMap,
            streamableAssetMap,
            videoThumbnailAssetMap,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredBonusContent]);

    const prepareLightBoxSlides = useCallback(async () => {
        const { imageAssetMap, streamableAssetMap, videoThumbnailAssetMap } = await fetchBonusContentSlideData();

        const slides: Slide[] = filteredBonusContent.map((bonusContent) => {
            if (bonusContent?.mimeType?.includes('image')) {
                return {
                    type: 'image',
                    src: imageAssetMap.get(bonusContent?.assetId),
                    title: bonusContent?.displayName,
                    download: bonusContent.downloadable,
                    onDownload: () => handleOnDownloadInSlide(bonusContent),
                } as GenericBonusContentSlide;
            } else if (bonusContent?.mimeType?.includes('video')) {
                return {
                    type: 'video',
                    src: streamableAssetMap.get(bonusContent?.assetId),
                    title: bonusContent?.displayName,
                    download: bonusContent.downloadable,
                    onRefreshStreamableUrl: () => handleOnRefreshStreamUrl(bonusContent),
                    assetId: bonusContent?.displayName,
                    poster: videoThumbnailAssetMap.get(bonusContent.assetId),
                    onDownload: () => handleOnDownloadInSlide(bonusContent),
                    pressingTitle: pressingTitle,
                    artistName: artistName,
                } as StreamableBonusContentSlide;
            } else if (bonusContent.mimeType?.includes('audio')) {
                return {
                    type: 'audio',
                    src: streamableAssetMap.get(bonusContent?.assetId),
                    title: bonusContent?.displayName,
                    download: bonusContent.downloadable,
                    onRefreshStreamableUrl: () => handleOnRefreshStreamUrl(bonusContent),
                    poster: editionArtworkUrl,
                    assetId: bonusContent?.displayName,
                    onDownload: () => handleOnDownloadInSlide(bonusContent),
                    pressingTitle: pressingTitle,
                    artistName: artistName,
                } as StreamableBonusContentSlide;
            } else {
                return {
                    type: 'other',
                    title: bonusContent?.displayName,
                    download: bonusContent.downloadable,
                    onDownload: () => handleOnDownloadInSlide(bonusContent),
                } as GenericBonusContentSlide;
            }
        });

        return setBonusContentSlides(slides);
    }, [
        artistName,
        editionArtworkUrl,
        fetchBonusContentSlideData,
        filteredBonusContent,
        handleOnDownloadInSlide,
        handleOnRefreshStreamUrl,
        pressingTitle,
    ]);

    useEffect(() => {
        if (!locked && filteredBonusContent.length) {
            prepareLightBoxSlides();
        }
    }, [filteredBonusContent.length, locked, prepareLightBoxSlides]);

    const slides = useMemo(() => {
        return bonusContentSlides;
    }, [bonusContentSlides]);

    useEffect(() => {
        if (openLightBox) {
            onOpenLightBox();
        }
    }, [openLightBox, onOpenLightBox]);

    useEffect(() => {
        const fetchDownloadUrls = async () => {
            const urlPromises = filteredBonusContent.map(async (content) => {
                if (content.assetId) {
                    const url = await onGetDownloadUrl(content.assetId);
                    return { assetId: content.assetId, url };
                }

                return null;
            });

            const results = await Promise.all(urlPromises);
            const urlMap = results.reduce(
                (acc, result) => {
                    if (result?.assetId && result.url) {
                        acc[result.assetId] = result.url;
                    }
                    return acc;
                },
                {} as { [key: string]: string },
            );

            setDownloadUrls(urlMap);
        };

        if (!locked) {
            fetchDownloadUrls();
        }
    }, [locked, bonusContents, onGetDownloadUrl, filteredBonusContent]);

    return (
        <div className={clsx(className, '')}>
            <div className={`flex flex-col ${showTitle ? 'mt-[4rem]' : 'mt-[0.9rem]'}`}>
                <div className="flex flex-row gap-4 justify-between items-center">
                    {showTitle === true && (
                        <div className="flex items-center" data-testid="bonus-title">
                            <Typography variant="bodyBold">{t('title')}</Typography>
                            <div className="relative">
                                <div className="ml-[0.3rem] absolute bottom-[0.5rem]">
                                    <Tooltip active="aboveLeft">{t('toolTip')}</Tooltip>
                                </div>
                            </div>
                        </div>
                    )}
                    {showStreamDownload === true && (
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full items-end max-w-[18rem]">
                                <DownloadAudioButton canDownloadAudio={false} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {!locked && (
                <ViewNow isLocked={slides.length === 0} heading="View Now" onClick={() => setOpenLightBox(true)} />
            )}

            <PanelContent className="max-h-[38rem]" totalItems={bonusContents.length || 0}>
                {bonusContents.map((bonusContent, index) =>
                    bonusContent.mimeType?.includes('markdown') ? (
                        <ProductBonusContentLink
                            key={`bonus-${bonusContent.assetId}`}
                            bonusContent={bonusContent}
                            onDownload={handleOnDownload}
                            isLoading={loadingStates[bonusContent.assetId!] || false}
                            locked={!isLinkConsumable(bonusContent) || locked}
                            onClick={() => {}}
                        />
                    ) : (
                        <ProductBonusContent
                            key={`bonus-${bonusContent.assetId}`}
                            bonusContent={bonusContent}
                            onDownload={handleOnDownload}
                            isLoading={loadingStates[bonusContent.assetId!] || false}
                            locked={!isConsumable(bonusContent) || locked}
                            onClick={() => {
                                if (isConsumable(bonusContent)) {
                                    setSlideIndex(index);
                                    setOpenLightBox(true);
                                }
                            }}
                        />
                    ),
                )}
            </PanelContent>
            <a id="downloadBonusContent" className="invisible" download />

            {slides.length > 0 && (
                <BonusContentLightBox
                    slides={slides}
                    open={openLightBox}
                    close={() => setOpenLightBox(false)}
                    showSlide={startSlideIndex}
                />
            )}
        </div>
    );
};
